import React from 'react'
import { useAtom } from "jotai"
import { userStore } from "./store"
import { useNavigate } from 'react-router-dom'
import { Navbar, Container} from "react-bootstrap"
import RegisterOrLogin from './registerOrLogin'
import AccountDropdown from './accountDropdown'

function TopNavBar(props) {
    const { logoutUser, notificationCount } = props
    const navigate = useNavigate()
    const [userLoggedIn, setUserLoggedIn] = useAtom(userStore)
    
    // className="bg-body-tertiary" bg="light" data-bs-theme="light"
    return (
        <Navbar expand="lg">
            <Container>
                <Navbar.Brand role="button" onClick={() => navigate("/")}>CampFriends</Navbar.Brand>
                {userLoggedIn.userId ?
                    <AccountDropdown logoutUser={logoutUser} notificationCount={notificationCount} />
                    :
                    <RegisterOrLogin userLoggedIn={userLoggedIn} setUserLoggedIn={setUserLoggedIn} />
                }
            </Container>
        </Navbar>
    )
}

export default TopNavBar
