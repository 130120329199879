import React, { useState } from "react"
import { Button, Alert } from "react-bootstrap"
import { fetchJson } from "./utils"
import { FaUserFriends } from "react-icons/fa";


const InviteFriendButton = (props) => {
  const { childId } = props
  const [invite, setInvite] = useState(null)

  const inviteFriend = (e) => {
    e.preventDefault()

    // effectively closes the button if already showing
    if (invite !== null) {
      setInvite(null)
      return
    }

    const params = {
      "child_id": childId
    }
    fetchJson("/api/get_invite_code", "get", params)
      .then(data => {
        if ("error" in data) {
          alert(data["error"])
        } else {
          const inviteCode = data.invite
          const inviteLink = "https://campfriends.io/friend/" + inviteCode;
          setInvite(inviteLink)
        }
      })
      .catch(error => alert("Error: " + error))
  }

  const copyToClipboard = () => {
    navigator.clipboard.writeText(invite)
  }

  return (
    <>
      <div className="sidebar-item">
        <Button variant="primary" onClick={inviteFriend}>
          Invite a Friend <FaUserFriends />
        </Button>
      </div>

      {invite != null && (
        <>
        <div style={{ flexBasis: "100%", height: "0"}}>a</div>
        <div style={{ width: "100%", display: "block" }}>
          <Alert variant="info">
            {invite}
            <Button variant="outline" onClick={copyToClipboard}>Copy</Button>
          </Alert>
        </div>
        </>
      )}
    </>
  )
}

export default InviteFriendButton