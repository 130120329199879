import React, { useEffect, useMemo, useState } from "react"
import { useAtom } from "jotai"
import { schoolsAtom, userStore, cacheFriendCalendarsAtom } from "./store"
import { JSON_FETCH_HEADERS } from './constants'
import { log, makeIdNameOptions, makeLookupArray, displayDateRange } from "./utils"
import { Row, Col, Form, Button, Accordion } from "react-bootstrap"
import FindFriendsModal from "./findFriendsModal"
import InviteFriendButton from "./inviteFriendButton"

const RequestFriend = (props) => {
    const { notify, childName, currentChildId, refreshUser } = props

    const resolveFriendRequest = (requestStatus) => {
        // requester/requestee are flipped here vs. other calls because the requester was the friend
        fetch(`/api/resolve_friend_request`, {
            method: "post",
            headers: JSON_FETCH_HEADERS,
            body: JSON.stringify({
                "requester_id": notify.friend_id,
                "requestee_id": notify.child_id,
                "request_status": requestStatus,
            })
        })
            .then((response) => response.json())
            .then(data => {
                if ("error" in data) {
                    alert(data["error"])
                } else {
                    refreshUser(currentChildId)
                }
            })
            .catch(error => alert("Request Friend Error: " + error))
    }
    const approveFriendRequest = () => resolveFriendRequest("approved")
    const rejectFriendRequest = () => resolveFriendRequest("rejected")

    return (
        <Row className="pb-2">
            <Col>
                <div className="notify-friend-name">{notify.friend_name}</div>
            </Col>
            <Col>
                <Button variant="primary" onClick={approveFriendRequest}>Approve</Button>
            </Col>
            <Col>
                <Button variant="danger" onClick={rejectFriendRequest}>Reject</Button>
            </Col>
            <Col></Col>
        </Row>
    )
}

const PrependingFriend = (props) => {
    const { friendId, friendName, currentChildId, childOptions, refreshUser } = props
    const [childId, setChildId] = useState(currentChildId ?? null)

    const requestPendingFriend = (e) => {
        e.preventDefault()

        fetch(`/api/request_friend`, {
            method: "post",
            headers: JSON_FETCH_HEADERS,
            body: JSON.stringify({
                "requester_id": childId,
                "requestee_id": friendId,
            })
        })
            .then((response) => response.json())
            .then(data => {
                if ("error" in data) {
                    alert(data["error"])
                } else {
                    refreshUser(currentChildId)
                }
            })
            .catch(error => alert("Request Friend Error: " + error))
    }

    return (
        <Form onSubmit={requestPendingFriend}>
            <Row>
                <Col>
                    <b>{friendName}</b>
                </Col>
                <Col>
                    <Form.Select
                        name="requester"
                        value={childId}
                        onChange={(e) => setChildId(e.target.value)}
                        required
                    >
                        {childOptions}
                    </Form.Select>
                </Col>
                <Col>
                    <Button variant="primary" type="submit">Request Friend</Button>
                </Col>
            </Row>
        </Form>
    )
}

// const PendingFriend = (props) => {
//     const { notify, childName } = props

//     return (
//         <Row>
//             <Col>
//                 <b>{childName}</b>
//             </Col>
//             <Col>
//                 <div className="awaiting-response">waiting for response from</div>
//             </Col>
//             <Col>
//                 <b>{notify.friend_name}</b>
//             </Col>
//         </Row>
//     )
// }

const ChildFriends = (props) => {
    const { child, pendingFriends, approveFriends, rejectFriends, friends } = props
    const [userLoggedIn, setUserLoggedIn] = useAtom(userStore)
    const [schools, setSchools] = useAtom(schoolsAtom)
    const [cacheFriendCalendars, setCacheFriendCalendars] = useAtom(cacheFriendCalendarsAtom)

    const [friendCalendar, setFriendCalendar] = useState([])

    useEffect(() => {
        getFriendCalendar(userLoggedIn.currentChildId)
    }, [])

    const getFriendCalendar = async (childId) => {
        if (childId === null) { return {} }
        const childIdStr = childId.toString()
        if (cacheFriendCalendars[childIdStr] !== undefined) {
            setFriendCalendar(cacheFriendCalendars[childIdStr])
        }
        try {
            const response = await fetch('/api/get_friend_calendars', {
                method: 'POST',
                headers: JSON_FETCH_HEADERS,
                body: JSON.stringify({ "child_id": childId })
            })
            if (!response.ok) {
                throw new Error('Network response was not ok')
            }
            const data = await response.json()
            log({data})
            // friend_calendar_lookup[child_id] = {
            //     "child_id": child.child_id,
            //     "fname": child.fname,
            //     "lname": child.lname,
            //     "full_name": child.full_name,
            //     "school_id": child.school_id,
            //     "friend_programs": friend_calendars[child.child_id],
            //     "show_schedule": True
            // }
            // log({data})
            setFriendCalendar(data)
            // avoid reloading this every time someone tabs between their children
            setCacheFriendCalendars({
                ...cacheFriendCalendars,
                [childIdStr]: data,
            })
        }
        catch (error) {
            console.error('getFriendCalendar Error:', error)
        }
    }

    return (
        <div className="px-2">
            <h3 className="notify-child-h3">{child.full_name}</h3>
            {friends.map((friend, idx) => {
                const isCurrentChild = friend.friend_id === userLoggedIn.currentChildId
                const programs = isCurrentChild ?
                    userLoggedIn.childrenCalendars[userLoggedIn.currentChildId]
                    : friendCalendar[friend.friend_id]?.["friend_programs"]
                return (
                    <span key={`${friend.child_id}_${friend.friend_id}`}>
                        <Accordion eventkey={idx}>
                            <Accordion.Header>
                                <div className="friend-cal">
                                    <div className="friend-cal-name">{friend.friend_name}</div>
                                    <div className="friend-cal-school">{schools[friend.school_id]?.school_name}</div>
                                    <div className="friend-cal-programs">{programs?.length ?? 0} camps</div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <ul>
                                    {programs?.length ?? 0 > 0 ?
                                        programs?.map((prog, idx) => {
                                            return (
                                                <li className="li-padding" key={idx}>
                                                    {prog.camp_name}{prog.camp_program_name ? `: ${prog.camp_program_name}` : null}
                                                    <ul><li>
                                                        {prog.registration_status} &mdash; {displayDateRange(prog.start_date, prog.end_date)}
                                                    </li></ul>
                                                </li>
                                            )
                                        })
                                        :
                                        (<li className="li-padding"><i>No camps scheduled.</i></li>)
                                    }
                                </ul>
                            </Accordion.Body>
                        </Accordion>
                    </span>
                )
            })}
            {friends.length === 0 && (
                <div className="px-4"><i>No friends yet!</i></div>
            )}
            {/* {approveFriends.map((friend) => {
                return (<Row key={`${friend.child_id}_${friend.friend_id}`}>
                    <Col>
                        <b>{friend.friend_name}</b>
                    </Col>
                    <Col>
                        <b><i>approved</i></b>
                    </Col>
                </Row>)
            })}
            {pendingFriends.map((friend) => {
                return (<Row key={`${friend.child_id}_${friend.friend_id}`}>
                    <Col>
                        <i>{friend.friend_name}</i>
                    </Col>
                    <Col>
                        <i>pending</i>
                    </Col>
                </Row>)
            })}
            {rejectFriends.map((friend) => {
                return (<Row key={`${friend.child_id}_${friend.friend_id}`}>
                    <Col>
                        <i>{friend.friend_name}</i>
                    </Col>
                    <Col>
                        <i>rejected</i>
                    </Col>
                </Row>)
            })} */}
            <hr />
        </div>
    )
}


const Friends = (props) => {
    const { refreshUser } = props
    // const navigate = useNavigate()
    const [userLoggedIn, setUserLoggedIn] = useAtom(userStore)

    // log({userLoggedIn})

    const childIds = useMemo(() => {
        if (!userLoggedIn) return []
        return userLoggedIn.children.map(child => child.child_id)
    }, [userLoggedIn])

    /* NOTIFICATION STATUSES
        prepending: special temp record when someone clicked on an invite link before logging in
        pending: awaiting friend's approval, set when you click an invite link
        requested: friend is awaiting your approval, set when they click your invite link
        approved: friend
        rejected: blocked
    */
    /*
    notifications structure:
    [{ child_id, friend_id, friend_name, status }]

    child_id: user's child, blank while prepending
    status: prepending, pending, requested, approved, rejected
    */

    const notifications = useMemo(() => userLoggedIn.notifications, [userLoggedIn])
    // log({notifications})
    // notifications that require action
    const requestedFriends = useMemo(() => notifications.filter((notify) => notify.status === "requested"), [notifications])
    const prependingFriends = useMemo(() => notifications.filter((notify) => notify.status === "prepending"), [notifications])
    // awaiting action by others
    const pendingFriends = useMemo(() => notifications.filter((notify) => notify.status === "pending"), [notifications])
    const pendingLookup = useMemo(() => makeLookupArray(pendingFriends, "child_id", [pendingFriends]))
    // log({pendingLookup})
    const approveFriends = useMemo(() => notifications.filter((notify) => notify.status === "approved"), [notifications])
    const approveLookup = useMemo(() => makeLookupArray(approveFriends, "child_id", [approveFriends]))
    // log({approveLookup})
    const rejectFriends = useMemo(() => notifications.filter((notify) => notify.status === "rejected"), [notifications])
    const rejectLookup = useMemo(() => makeLookupArray(rejectFriends, "child_id", [rejectFriends]))
    // log({rejectLookup})

    const childOptions = makeIdNameOptions(Object.values(userLoggedIn.children), "child_id", "full_name")

    function getChildName(child_id) {
        return userLoggedIn.children.filter(child => child.child_id === child_id)?.[0]?.full_name
    }

    /*
    userId: null,
    email: null,
    currentChildId: null,
    children: [],
    childrenCalendars: [],
*/
    // Structure of:
    // Pending Friend Requests (confirm a friend that initiated a connection)
    // Add a Friend (after clicking an invite link, select which of your children to request a friendship with)
    // Sections for each child with:
    // - list of groups/friends
    // - search to find a new friend
    return (
        <div id="main" className="px-4 pt-3">
            {prependingFriends.length > 0 && (
                <div>
                    <h1>Add a Friend</h1>
                    {prependingFriends.map((notify) => {
                        return (
                            <PrependingFriend
                                key={notify.friend_id}
                                friendId={notify.friend_id}
                                friendName={notify.friend_name}
                                currentChildId={userLoggedIn.currentChildId}
                                childOptions={childOptions}
                                refreshUser={refreshUser}
                            />
                        )
                    })}
                    <hr />
                </div>
            )}

            {requestedFriends.length > 0 && (
                <div>
                    <h1>Friend Requests</h1>
                    {childIds.map((childId) => {
                        return (
                            <div key={childId}>
                                <h3 className="notify-child-h3">{getChildName(childId)}</h3>
                                {requestedFriends.filter((notify) => notify.child_id === childId).map((notify) => {
                                    return (
                                        <RequestFriend
                                            key={`${notify.child_id}_${notify.friend_id}`}
                                            notify={notify}
                                            childName={getChildName(notify.child_id)}
                                            currentChildId={userLoggedIn.currentChildId}
                                            refreshUser={refreshUser}
                                        />
                                    )
                                })}
                            </div>
                        )
                    })}
                    <hr />
                </div>
            )}
            {/* {pendingFriends.length > 0 && (
                <div>
                    <h2>Pending Friend Requests</h2>
                    {pendingFriends.map((notify) => {
                        return (
                            <PendingFriend
                                key={`${notify.child_id}_${notify.friend_id}`}
                                notify={notify}
                                childName={childLookup[notify.child_id].full_name}
                            />
                        )
                    })}
                </div>
            )} */}
            {childIds.length > 0 && (
                <div className="pt-3">
                    <h1>Friends</h1>
                    
                    {userLoggedIn.currentChildId && (
                        // <div className="w-100 text-center p-2">
                        <div style={{ width: "100%", alignItems: "center", justifyContent: "center", display: "flex", flexWrap: "wrap" }}>
                            <FindFriendsModal refreshUser={refreshUser} />
                            {userLoggedIn && userLoggedIn.currentChildId && (
                                <InviteFriendButton childId={userLoggedIn.currentChildId} />
                            )}
                        </div>
                    )}

                    {childIds.map((childId) => {
                        return (
                            <ChildFriends
                                key={childId}
                                child={userLoggedIn.children.filter(c => c.child_id === childId)?.[0] ?? {}}
                                pendingFriends={pendingLookup[childId] ?? []}
                                approveFriends={approveLookup[childId] ?? []}
                                rejectFriends={rejectLookup[childId] ?? []}
                                friends={userLoggedIn.friends[childId.toString()] ?? []}
                            />
                        )
                    })}
                </div>
            )}
        </div>
    )
}

export default Friends