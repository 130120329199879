import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './app'
import './index.css'
// import 'bootstrap/dist/css/bootstrap.min.css'
import './minty.min.css'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
)