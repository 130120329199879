import React, { useState, useCallback, useMemo, useEffect } from "react"
import { useAtom } from "jotai"
import { userStore, campsAtom, campsVersionAtom, friendCalendarsAtom, showFriendIdsAtom, cacheFriendCalendarsAtom } from "./store"
import LandingPage from "./landingPage"
import LeftSidebar from "./leftSidebar"
import MyCalendar from "./calendar"
import CurrentChildTabs from "./currentChildTabs"
import ProgramDetailsModal from "./programDetailsModal"
import AdminButtons from "./admin"

import { Container, Row, Col } from "react-bootstrap"
import { BADGE_COLORS, JSON_FETCH_HEADERS, EMPTY_SELECTED_PROGRAM } from './constants'
import { log, makeLookup } from "./utils"
import AddCampModal from "./addCampModal"
import CreateCampModal from "./createCampModal"
import CreateCampProgramModal from "./createCampProgramModal"
import WeeklyOverview from "./weeklyOverview"

const Home = (props) => {
  const { refreshUser } = props
  const [userLoggedIn, setUserLoggedIn] = useAtom(userStore)
  const [camps, setCamps] = useAtom(campsAtom)
  const [campsVersion, setCampsVersion] = useAtom(campsVersionAtom)
  const [friendCalendars, setFriendCalendars] = useAtom(friendCalendarsAtom)
  const [showFriendIds, setShowFriendIds] = useAtom(showFriendIdsAtom)
  const [cacheFriendCalendars, setCacheFriendCalendars] = useState(cacheFriendCalendarsAtom)

  const [currentChildId, setCurrentChildId] = useState(null)
  const [currentChildSchedule, setCurrentChildSchedule] = useState([])
  /*
    id: null,
    camp_attendee_id: null,
    child_id: null,
    camp_id: null,
    camp_program_id: null,
    title: null,
    campName: null,
    campProgramName: null,
    programStart: null,
    programEnd: null,
    schedule_id: null,
    schedule: null,
    fullProgram: true,
    start_date: null,
    end_date: null,
    start: null,
    end: null,
    allDay: true,
    details: null,
    friends: [],
    registrationStatus: "none",
  */
  const [selectedProgram, setSelectedProgram] = useState(EMPTY_SELECTED_PROGRAM)
  const [showSelectedProgram, setShowSelectedProgram] = useState(false)
  const [showAddCamp, setShowAddCamp] = useState(false)
  const [programFriends, setProgramFriends] = useState({})    // lookup table of [camp_program_id]: [{ friendId, registrationStatus }]
  const [newCampName, setNewCampName] = useState("")
  const [newCampProgramName, setNewCampProgramName] = useState("")
  const [newCampProgramCampId, setNewCampProgramCampId] = useState(null)
  const [selectedCampId, setSelectedCampId] = useState(0)
  const [selectedCampProgramId, setSelectedCampProgramId] = useState(0)


  /*
    CAMPS OBJECT STRUCTURE
    camps[camp_id] = {
      "camp_id": row.camp_id,
      "camp_name": row.camp_name,
      "camp_parent_company": row.camp_parent_company,
      "url": row.url,
      "phone": row.phone,
      "location": row.location,
      "age_or_grade": row.age_or_grade,
      "aftercare": row.aftercare,
      "beforecare": row.beforecare,
      "cost": row.cost,
      "notes": row.notes,
      "programs": [{
        "camp_program_id": row.camp_program_id,
        "camp_program_name": row.camp_program_name,
        "start_date": row.start_date.isoformat(),
        "end_date": row.end_date.isoformat(),
      }]
    }
  */

  useEffect(() => {
    if (userLoggedIn && userLoggedIn.currentChildId !== currentChildId) {
      setCurrentChildId(userLoggedIn.currentChildId)
    }
  }, [userLoggedIn, currentChildId])

  const getFriendCalendars = async (childId) => {
    if (childId === null) { return {} }
    // console.log("getFriendCalendars", childId)
    // console.log(JSON.stringify(cacheFriendCalendars))
    if (cacheFriendCalendars[childId.toString()] !== undefined) {
      setFriendCalendars(cacheFriendCalendars[childId])
      setShowFriendIds(Object.values(cacheFriendCalendars[childId]).filter(fc => fc.show_schedule).map(fc => fc.child_id))
      return
    }
    try {
        const response = await fetch('/api/get_friend_calendars', {
          method: 'POST',
          headers: JSON_FETCH_HEADERS,
          body: JSON.stringify({"child_id": childId})
        })
        if (!response.ok) {
          throw new Error('Network response was not ok')
        }
        const data = await response.json()
        // friend_calendar_lookup[child_id] = {
        //     "child_id": child.child_id,
        //     "fname": child.fname,
        //     "lname": child.lname,
        //     "full_name": child.full_name,
        //     "school_id": child.school_id,
        //     "friend_programs": friend_calendars[child.child_id],
        //     "show_schedule": True
        // }
        setFriendCalendars(data)
        setShowFriendIds(Object.values(data).filter(fc => fc.show_schedule).map(fc => fc.child_id))
        // avoid reloading this every time someone tabs between their children
        setCacheFriendCalendars({
          ...cacheFriendCalendars,
          [childId]: data,
        })
    }
    catch (error) {
        console.error('getFriendCalendars Error:', error)
    }
  }

  useEffect(() => {
    // load (from db or cache) the data of all friend
    if (userLoggedIn.currentChildId) {
      getFriendCalendars(userLoggedIn.currentChildId)
    }
  }, [userLoggedIn.currentChildId])



  const handleSelectEvent = React.useCallback((event) => {
    // for some reason, if friends is an empty array when passed to calendar, big calendar removes it from the event object
    // so we add an empty array back
    const newEvent = event["friends"] !== undefined ? {...event} : {...event, "friends": []}
    setSelectedProgram(newEvent)
    setShowSelectedProgram(true)
  }, [])

  // <div id="signInDiv"></div>

  // list of dictionaries from db
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString


  // const addEvent = useCallback(
  //   (newEvent) => {
  //     setEvents([...events, newEvent])
  //   },
  //   [events, setEvents]
  // )

  const setCurrentChildProgramStart = (campAttendeeId, campProgramId, newStart) => {
    const currentChildIdStr = userLoggedIn.currentChildId.toString()
    let newChildrenCalendars = {
      ...userLoggedIn.childrenCalendars,
      [currentChildIdStr]: userLoggedIn.childrenCalendars[userLoggedIn.currentChildId].map(cc => {
        if (cc.camp_attendee_id === campAttendeeId) {
          return {
            ...cc,
            start_date: newStart,
            start: new Date(newStart),
          }
        } else {
          return cc
        }
      })
    }
    // update the current child programs
    const user = {
      ...userLoggedIn,
      "childrenCalendars": newChildrenCalendars
    }
    setUserLoggedIn(user)
    localStorage.setItem('user', JSON.stringify(user))
  }

  const setCurrentChildProgramEnd = (campAttendeeId, campProgramId, newEnd) => {
    const currentChildIdStr = userLoggedIn.currentChildId.toString()
    let newChildrenCalendars = {
      ...userLoggedIn.childrenCalendars,
      [currentChildIdStr]: userLoggedIn.childrenCalendars[userLoggedIn.currentChildId].map(cc => {
        if (cc.camp_attendee_id === campAttendeeId) {
          return {
            ...cc,
            end_date: newEnd,
            end: new Date(newEnd),
          }
        } else {
          return cc
        }
      })
    }
    // update the current child programs
    const user = {
      ...userLoggedIn,
      "childrenCalendars": newChildrenCalendars
    }
    setUserLoggedIn(user)
    localStorage.setItem('user', JSON.stringify(user))
  }

  const setCurrentChildProgramStatus = (campAttendeeId, campProgramId, registrationStatus) => {
  /*
      userId: null,
      email: null,
      currentChildId: null,
      children: [{
          child_id
          user_id
          fname
          lname
          full_name
          school
          invite_code
      }],
      childrenCalendars: {
          child_id: [{
              "camp_attendee_id": row.camp_attendee_id,
              "camp_id": row.camp_id,
              "camp_name": row.camp_name,
              "camp_program_id": row.camp_program_id,
              "camp_program_name": row.camp_program_name,
              "full_program": row.full_program,
              "start_date": row.start_date.isoformat(),
              "end_date": row.end_date.isoformat(),
              "registration_status": row.registration_status,
          }]
      },
      friends: [],
      notifications: [],
      isAdmin: false,
  */
    const currentChildIdStr = userLoggedIn.currentChildId.toString()

    // if changing the status of a program that remains in the child calendar, like waitlisted -> registered
    const isInChildCalendars = userLoggedIn.childrenCalendars[userLoggedIn.currentChildId].some(cc => cc.camp_attendee_id === campAttendeeId)
    let newChildrenCalendars = {}
    if (isInChildCalendars) {
      // if setting to none, we remove from the child programs
      if (registrationStatus === "none") {
        newChildrenCalendars = {
          ...userLoggedIn.childrenCalendars,
          [currentChildIdStr]: userLoggedIn.childrenCalendars[userLoggedIn.currentChildId].filter(cc => cc.camp_attendee_id !== campAttendeeId)
        }
      // otherwise, update the existing status in place
      } else {
        newChildrenCalendars = {
          ...userLoggedIn.childrenCalendars,
          [currentChildIdStr]: userLoggedIn.childrenCalendars[userLoggedIn.currentChildId].map(cc => {
            if (cc.camp_attendee_id === campAttendeeId) {
              return {
                ...cc,
                registration_status: registrationStatus
              }
            } else {
              return cc
            }
          })
        }
      }
    } else {
      newChildrenCalendars = { ...userLoggedIn.childrenCalendars }
    }

    // program currently not in child programs so it must be in the friend programs.
    // remove from friend programs and add to the child program.
    if (!isInChildCalendars) {
      // extract the program from the friends calendar and move it
      const newChildProgram = Object.values(friendCalendars).find(
        fc => fc.friend_programs.some(
          fcs => fcs.camp_program_id === campProgramId
        )
      ).friend_programs.filter(
        fcs => fcs.camp_program_id === campProgramId
      )[0]
      newChildProgram.registration_status = registrationStatus
       
      // add the program to the current child programs
      newChildrenCalendars[currentChildIdStr].push(newChildProgram)
    }

    // update the current child programs
    const user = {
      ...userLoggedIn,
      "childrenCalendars": newChildrenCalendars
    }
    setUserLoggedIn(user)
    localStorage.setItem('user', JSON.stringify(user))
  }

  const setMyProgramStart = useCallback(
    (newStart) => {
      if (newStart && newStart !== selectedProgram.start_date) {
        const attendee_url = selectedProgram.camp_attendee_id === null ? `/api/add_camp_attendee` : `/api/update_camp_attendee`
        const fullProgram = newStart === selectedProgram.program_start && selectedProgram.end_date === selectedProgram.program_end
        fetch(attendee_url, {
          method: "post",
          headers: JSON_FETCH_HEADERS,
          body: JSON.stringify({
            "camp_attendee_id": selectedProgram.camp_attendee_id ?? null,
            "child_id": userLoggedIn.currentChildId,
            "camp_program_id": selectedProgram.camp_program_id,
            "full_program": fullProgram,
            "start_date": newStart,
            "end_date": selectedProgram.end_date,
            "registration_status": selectedProgram.registrationStatus,
          })
        })
        .then((response) => response.json())
        .then(data => {
            if ("error" in data) {
              alert(data["error"])
            } else {
              // shows selected value in dropdown
              setSelectedProgram({
                ...selectedProgram,
                start_date: newStart,
                start: new Date(newStart),
              })
              // triggers update in user object
              setCurrentChildProgramStart(selectedProgram.camp_attendee_id, selectedProgram.camp_program_id, newStart)
            }
        })
        .catch(error => console.error("Update camp attendee start error:", error))
      }
    },
    [userLoggedIn.currentChildId, selectedProgram]
  )

  const setMyProgramEnd = useCallback(
    (newEnd) => {
      if (newEnd && newEnd !== selectedProgram.end_date) {
        const attendee_url = selectedProgram.camp_attendee_id === null ? `/api/add_camp_attendee` : `/api/update_camp_attendee`
        const fullProgram = selectedProgram.start_date === selectedProgram.program_start && newEnd === selectedProgram.program_end
        fetch(attendee_url, {
          method: "post",
          headers: JSON_FETCH_HEADERS,
          body: JSON.stringify({
            "camp_attendee_id": selectedProgram.camp_attendee_id ?? null,
            "child_id": userLoggedIn.currentChildId,
            "camp_program_id": selectedProgram.camp_program_id,
            "full_program": fullProgram,
            "start_date": selectedProgram.start_date,
            "end_date": newEnd,
            "registration_status": selectedProgram.registrationStatus,
          })
        })
        .then((response) => response.json())
        .then(data => {
            if ("error" in data) {
              alert(data["error"])
            } else {
              // shows selected value in dropdown
              setSelectedProgram({
                ...selectedProgram,
                end_date: newEnd,
                end: new Date(newEnd),
              })
              // triggers update in user object
              setCurrentChildProgramEnd(selectedProgram.camp_attendee_id, selectedProgram.camp_program_id, newEnd)
            }
        })
        .catch(error => console.error("Update camp attendee end error:", error))
      }
    },
    [userLoggedIn.currentChildId, selectedProgram]
  )

  const setMyProgramStatus = useCallback(
    (myStatus) => {
      if (myStatus && myStatus !== selectedProgram.registrationStatus) {
        const attendee_url = selectedProgram.camp_attendee_id === null ? `/api/add_camp_attendee` : `/api/update_camp_attendee`
        const fullProgram = selectedProgram.start_date === selectedProgram.program_start && selectedProgram.end_date === selectedProgram.program_end
        fetch(attendee_url, {
          method: "post",
          headers: JSON_FETCH_HEADERS,
          body: JSON.stringify({
            "camp_attendee_id": selectedProgram.camp_attendee_id ?? null,
            "child_id": userLoggedIn.currentChildId,
            "camp_program_id": selectedProgram.camp_program_id,
            "full_program": fullProgram,
            "start_date": selectedProgram.start_date,
            "end_date": selectedProgram.end_date,
            "registration_status": myStatus,
          })
        })
        .then((response) => response.json())
        .then(data => {
            if ("error" in data) {
              alert(data["error"])
            } else {
              // shows selected value in dropdown
              setSelectedProgram({...selectedProgram, registrationStatus: myStatus })
              // triggers update in user object
              setCurrentChildProgramStatus(selectedProgram.camp_attendee_id, selectedProgram.camp_program_id, myStatus)
            }
        })
        .catch(error => console.error("Update camp attendee error:", error))
      }
    },
    [userLoggedIn.currentChildId, selectedProgram]
  )

  /* children and current schedules for each child is included in the userLoggedIn object
    userLoggedIn:
     userId: null,
     email: null,
     currentChildId: null,
     children: [],
     childrenCalendars: [],
  */
  const currentChildCalendar = useMemo(() => {
    return currentChildId == null ? [] : userLoggedIn.childrenCalendars[currentChildId]
  }, [currentChildId, userLoggedIn.childrenCalendars])

  const friendLookup = useMemo(() => {
    const friendData = Object.values(friendCalendars).map((fc, idx) => {
      const firstInitial = fc.fname.length > 0 ? fc.fname[0].toUpperCase() : "_ "
      const lastInitial = fc.lname.length > 0 ? fc.lname[0].toUpperCase() : "_"
      return {
        child_id: fc.child_id,
        fname: fc.fname,
        lname: fc.lname,
        full_name: fc.full_name,
        badge_label: firstInitial + lastInitial,
        badge_color: BADGE_COLORS[idx % BADGE_COLORS.length],
      }
    })
    return makeLookup(friendData, "child_id")
  }, [friendCalendars])

  const currentChildCalendarLength = currentChildCalendar?.length ?? 0
  
  // update currentChildSchedule if the child changes or the childCalendar is reloaded
  useEffect(() => {
    if (!currentChildCalendar || currentChildCalendar.length === 0) {
      setCurrentChildSchedule([])
      return
    }
    const curSched = currentChildCalendar.map((program, i) => {
      // this is the shape of the data required by the calendar to show
      return {
        id: i,
        camp_attendee_id: program.camp_attendee_id,
        child_id: currentChildId,
        camp_id: program.camp_id,
        camp_program_id: program.camp_program_id,
        title: program.camp_program_name ? program.camp_name + ": " + program.camp_program_name : program.camp_name,
        campName: program.camp_name,
        campProgramName: program.camp_program_name,
        programStart: program.program_start,
        programEnd: program.program_end,
        schedule_id: program.schedule_id,
        schedule: program.schedule,
        fullProgram: program.full_program,
        start_date: program.start_date,
        end_date: program.end_date,
        start: new Date(program.start_date),
        end: new Date(program.end_date),
        allDay: true,
        details: "",
        friends: [],  // always empty and populated inside Calendar
        registrationStatus: program.registration_status,
      }
    })
    setCurrentChildSchedule(curSched)
  }, [currentChildId, currentChildCalendar])

  const currentChildProgramIds = useMemo(() => {
    return currentChildSchedule?.map(sess => sess.camp_program_id) ?? []
  }, [currentChildSchedule])

  // build the schedules for friends and augment the current child's schedule from the 
  // friend calendar lookup table with this structure:
  // for child in friends:
  //     friend_calendar_lookup[child_id] = {
  //         "child_id": child.child_id,
  //         "fname": child.fname,
  //         "lname": child.lname,
  //         "full_name": child.full_name,
  //         "school": child.school,
  //         "friend_programs": friend_calendars[child.child_id],
  //         "show_schedule": True
  //     }
  const friendSchedules = useMemo(() => {  
    let sessionKey = currentChildCalendarLength
    // rebuilds programFriends and friendSchedules from scratch on any change to the friendCalendars
    const newProgramFriends = {}
    const newFriendSchedules = []
    for (const friendCalendar of Object.values(friendCalendars)) {
      let friendId = friendCalendar["child_id"]

      // don't show friend data in event details modal if friend isn't shown
      if (!showFriendIds.includes(friendId)) {
        continue
      }
      // doing two things here
      // 1) pulling out the program schedule from friend_programs. If 2 friends are attending we only add the first instance.
      // 2) for each friend program, add a programFriend entry to the programFriends lookup
      for (const program of friendCalendar["friend_programs"]) {
        const programFriend = {
          friendId: friendId,
          start_date: program.start_date,
          end_date: program.end_date,
          start: program.start,
          end: program.end,
          registrationStatus: program.registration_status
        }
        // instantiate the programFriends entry if necessary
        if (newProgramFriends[program.camp_program_id] === undefined) {
          // we add the programFriend further down
          newProgramFriends[program.camp_program_id] = [programFriend]
          // the child doesn't already have a record for the program we add it now
          if (!currentChildProgramIds.includes(program.camp_program_id)) {
            newFriendSchedules.push({
              id: sessionKey,
              camp_attendee_id: program.camp_attendee_id,
              child_id: currentChildId,
              camp_id: program.camp_id,
              camp_program_id: program.camp_program_id,
              title: program.camp_program_name ? program.camp_name + ": " + program.camp_program_name : program.camp_name,
              campName: program.camp_name,
              campProgramName: program.camp_program_name,
              programStart: program.program_start,
              programEnd: program.program_end,
              schedule_id: program.schedule_id,
              schedule: program.schedule,
              start_date: program.start_date,
              end_date: program.end_date,
              start: new Date(program.start_date),
              end: new Date(program.end_date),
              details: "",
              friends: [],  // always empty and populated inside Calendar component
              registrationStatus: "none",
            })
            sessionKey += 1
          }
        // camp program exists but friend isn't added yet       
        } else {
          newProgramFriends[program.camp_program_id].push(programFriend)
        }
      }
    }
    setProgramFriends(newProgramFriends)
    return newFriendSchedules
  }, [friendCalendars, currentChildId, currentChildProgramIds, currentChildCalendarLength])


  // all the schedules to show on the calendar. The current child and any selected friends' programs.
  const showCalendarSchedules = useMemo(() => {
    // filter friendSchedules to program ids that have a friend in showFriendIds and programFriends lookup
    const showFriendSchedules = friendSchedules.filter(
      fs => {
        const sessFriends = programFriends[fs.camp_program_id.toString()]
        const programFriendIds = (sessFriends ?? []).map(sf => sf.friendId)
        return showFriendIds.some(sfi => programFriendIds.includes(sfi))
      }
    )
    return [
      ...currentChildSchedule,
      ...showFriendSchedules,
    ]
  }, [currentChildSchedule, friendSchedules, showFriendIds, programFriends])

  return (
    <div id="main">
      {!userLoggedIn || !userLoggedIn.userId ? <LandingPage /> : (
        <span>
          <Container fluid>
            <Row>
              {/* <Col xs="12" sm="12" md="2">
                {currentChildId && <div className="w-100 text-center p-2">
                  <AddCampModal
                    currentChildId={currentChildId}
                    refreshUser={refreshUser}
                    camps={camps}
                    selectedCampId={selectedCampId}
                    setSelectedCampId={setSelectedCampId}
                    selectedCampProgramId={selectedCampProgramId}
                    setSelectedCampProgramId={setSelectedCampProgramId}
                    setNewCampName={setNewCampName}
                    setNewCampProgramName={setNewCampProgramName}
                    setNewCampProgramCampId={setNewCampProgramCampId}
                    />
                </div>}

                <LeftSidebar refreshUser={refreshUser} />

                {userLoggedIn.isAdmin && (
                  <div className="w-100 text-center p-2" style={{ backgroundColor: "#fdd"}}>
                    <AdminButtons camps={camps} campsVersion={campsVersion} setCampsVersion={setCampsVersion} />
                  </div>
                )}
              </Col> */}
              <Col>
                <CurrentChildTabs />
                <WeeklyOverview
                  showCalendarSchedules={showCalendarSchedules}
                  programFriends={programFriends}
                  handleSelectEvent={handleSelectEvent}
                  friendLookup={friendLookup}

                  currentChildId={currentChildId}
                  refreshUser={refreshUser}
                  camps={camps}
                  selectedCampId={selectedCampId}
                  setSelectedCampId={setSelectedCampId}
                  selectedCampProgramId={selectedCampProgramId}
                  setSelectedCampProgramId={setSelectedCampProgramId}
                  setNewCampName={setNewCampName}
                  setNewCampProgramName={setNewCampProgramName}
                  setNewCampProgramCampId={setNewCampProgramCampId}
/>
                {/* <MyCalendar
                  showCalendarSchedules={showCalendarSchedules}
                  programFriends={programFriends}
                  handleSelectEvent={handleSelectEvent}
                  friendLookup={friendLookup}
                /> */}
              </Col>
            </Row>
          </Container>

          {showAddCamp && (
            <AddCampModal
              currentChildId={currentChildId}
              refreshUser={refreshUser}
              camps={camps}
              selectedCampId={selectedCampId}
              setSelectedCampId={setSelectedCampId}
              selectedCampProgramId={selectedCampProgramId}
              setSelectedCampProgramId={setSelectedCampProgramId}
              setNewCampName={setNewCampName}
              setNewCampProgramName={setNewCampProgramName}
              setNewCampProgramCampId={setNewCampProgramCampId}
              setShowAddCamp={setShowAddCamp}
            />
          )}

          {/* show the program details modal when user clicks on an event in the calendar */}
          {showSelectedProgram && selectedProgram && <ProgramDetailsModal
            camp={camps[selectedProgram.camp_id]}
            selectedProgram={selectedProgram}
            setShowSelectedProgram={setShowSelectedProgram}
            setMyProgramStart={setMyProgramStart}
            setMyProgramEnd={setMyProgramEnd}
            setMyProgramStatus={setMyProgramStatus}
            friendLookup={friendLookup}
          />}

          {/* create camp modal */}
          {newCampName && <CreateCampModal
            newCampName={newCampName}
            setNewCampName={setNewCampName}
            campsVersion={campsVersion}
            setCampsVersion={setCampsVersion}
            setSelectedCampId={setSelectedCampId}
          />}
          {/* create camp program modal */}
          {newCampProgramName && newCampProgramCampId && <CreateCampProgramModal
            newCampProgramCampId={newCampProgramCampId}
            newCampProgramName={newCampProgramName}
            setNewCampProgramName={setNewCampProgramName}
            camps={camps}
            campsVersion={campsVersion}
            setCampsVersion={setCampsVersion}
            setSelectedCampProgramId={setSelectedCampProgramId}
          />}
        </span>
      )}
    </div>
  )
}

export default Home
